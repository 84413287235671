import Bugsnag from '@bugsnag/js'
window.Bugsnag = Bugsnag

import ferry_data_from_island from './ext/data_island'
const { user, release_stage, api_key, app_version } =
  ferry_data_from_island('error_tracking')

Bugsnag.start({
  apiKey: api_key,
  appVersion: app_version,
  releaseStage: release_stage,
  enabledReleaseStages: ['production', 'staging'],
  user: {
    id: user.id,
    name: user.name,
    email: user.email,
  },
  metadata: {
    company_id: user.company_id,
    is_admin: user.is_admin,
  },
  onError(event) {
    const error = event.originalError

    // Do not report when requests are aborted
    if (error.name === 'AbortError') return false

    // Do not report 401s
    if (error.name === 'FetchError' && error.status === 401) return false
  },
})
